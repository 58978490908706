$(window).on("resize", function (){
	if ($(this).width() > 1025) {
		if (window.device == 'mobile') {
			location.reload()
		}
		window.device = 'desktop';
	}else{
		if (window.device == 'desktop') {
			location.reload()
		}
		window.device = 'mobile';
	}
}).trigger("resize")

$.fn.ryderCool = function(option) {
	return this.each(function() {
		var $this = $(this);

		var deFault = {
			hook: 0.9,
			repeat: false,
			enter_check: true,
			leave_check: true,
			count: 0,
			enter() {},
			leave() {}
		};

		var setting = $.extend(deFault, option);

		function ryderScrolling() {
			var scrollTop = $(window).scrollTop(),
				elementOffset = $this.offset().top,
				distance = (elementOffset - scrollTop),
				windowHeight = $(window).height(),
				breakPoint = windowHeight * setting.hook,
				leavePoint = $this.height() - windowHeight * (1 - setting.hook);

			if (distance > breakPoint || distance < -leavePoint) {

				if (setting.count >= 1) {
					setting.enter_check = setting.repeat;
				}

				setting.leave_check && setting.leave($this);
				setting.leave_check = false;

			}else if (distance < breakPoint) {

				setting.enter_check && setting.enter($this);
				setting.enter_check && setting.count++;
				setting.enter_check = false;
				setting.leave_check = true;
			}
		}

		$(window).on("scroll", ryderScrolling).trigger("scroll");
	});
};

class RyderMarquee {
	constructor(el, direct = 0) {
		this.hero = $(el).parent().get(0)
		this.wrapper = el
		this.delta = 0
		this.transform = 0
		this.step = (device == 'mobile') ? 0.4 : 0.8
		this.direct = direct % 2
		this.hover = false

		if (this.direct == 1) {
			this.wrapper.style.transform = `translate3d(-${this.wrapper.getBoundingClientRect().width / 2}px, 0, 0)`;
			this.transform = -this.wrapper.getBoundingClientRect().width / 2
		}

		// if (device != 'mobile') {
		// 	$(el).hover(() => {
		// 		this.hover = true
		// 	}, () => {
		// 		this.hover = false
		// 	})
		// }
	}

	animate() {
		if (!this.hover) {
			this.transform += this.step
		}

		if (this.direct == 1) {
			if (this.transform > 0) {
				this.transform = -this.wrapper.getBoundingClientRect().width / 2;
			}
			this.wrapper.style.transform = `translate3d(${this.transform}px, 0, 0)`;
		} else {
			if (this.transform > this.wrapper.getBoundingClientRect().width / 2) {
				this.transform = 0;
			}
			this.wrapper.style.transform = `translate3d(-${this.transform}px, 0, 0)`;
		}
	}

	render() {
		this.scrollY = $(window).scrollTop()

		const bounding = this.hero.getBoundingClientRect();
		const distance = (window.innerHeight + this.scrollY) - (bounding.top + this.scrollY);
		const percentage = distance / ((window.innerHeight + bounding.height) / 100);

		this.animate();
	}

	create() {
		gsap.ticker.add(this.render.bind(this));
	}
}

// 分享
$("[data-share]").each((i, el) => {
    var type = el.dataset.share
    $(el).click(function(e) {
        e.preventDefault();

        var winHeight = 360;
        var winWidth = 600;
        var winTop = (screen.height / 2) - (winHeight / 2);
        var winLeft = (screen.width / 2) - (winWidth / 2);
        var url = $(this).attr("href");

        if(type == "facebook") {
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
        } else if(type == "twitter") {
            window.open('https://twitter.com/share?url=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
        } else if(type == "pinterest") {
            window.open('https://www.pinterest.com/pin/create/button/?url=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
        } else if(type == "googleplus") {
            window.open('https://plus.google.com/share?url=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
        } else if(type == "linkedin") {
            window.open('https://www.linkedin.com/cws/share?url=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
        } else if(type == "weibo") {
            window.open('https://service.weibo.com/share/share.php?url=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
        } else if(type == "line") {
            window.open('https://line.naver.jp/R/msg/text/?' + url);
        } else if(type == "copy") {
            copy(url)
            alert("連結已複製完成!");
        }
    });
});

function copy(s) {
    var clip_area = document.createElement('textarea');
    clip_area.textContent = s;

    document.body.appendChild(clip_area);
    clip_area.select();

    document.execCommand('copy');
    clip_area.remove();
}

function footerEnter(){
	var _move_up = gsap.timeline({
        paused: 0,
    }).fromTo("#footer-sun", {
        y: `${$(".footerWrap").height()}px`,
    }, {
    	y: 0,
    	duration: 1.5,
    	ease: "power1.out",
    })

	const Observer = new IntersectionObserver((entries, observer) => {
	    entries.forEach(entry => {
	        if(entry.isIntersecting) {
	       		_move_up.restart()
	        }
	    });
	}, {});

	if($(".footerWrap")[0]){
		Observer.observe($(".footerWrap")[0]);
	}
}


$(".marquee").each(function (i, el) {
	var $copy = $(el).contents().clone()
	$(el).append($copy)

	var ryderMarquee = new RyderMarquee(el, i).create()
})

$(".menuOpen").on("click", function(){
	$(this).toggleClass("is-open")
	
	$(this).toggleClass("is-white")

	$(".menuWrap").toggleClass("opacity-0 pointer-events-none")
})

$("#backtotop").on("click", function(){
	gsap.to($(window), 1.2, {
		scrollTo: {
			y: 'body',
		},
		ease:Power2.easeInOut,
		onComplete: function() {}
	})
})

$(window).on("mousemove", (e) => {
	let _x = e.clientX
	let _y = e.clientY

	$("#blob").css({
		top: _y,
		left: _x,
	})
})


function titleEnter(){
	const Observer = new IntersectionObserver((entries, observer) => {
	    entries.forEach(entry => {
	        if(entry.isIntersecting) {
	       		$("[data-enter]").addClass("is-enter")
	        }
	    });
	}, {});
	if($("[data-enter]")[0]){
		Observer.observe($("[data-enter]")[0]);
	}
}

// $(".menuWrap .close").on("click", function(){
// 	$(".menuWrap").addClass("opacity-0 pointer-events-none");
// })

var _scrollLast = 0
$(window).on("scroll", () => {
	var _scrollTop = $(window).scrollTop()
	var _allBottom = $("body").height() - $(window).height() - 100

	if (_scrollTop < 0 || _scrollTop > _allBottom) {
		return false;
	}

	if (_scrollTop > _scrollLast) {
		$("#topmenuWrap").addClass("is-small")
		$(".menuOpen").addClass("is-small")
	} else {
		// $("#topmenuWrap").removeClass("is-small")
	}

	_scrollLast = _scrollTop
})




$(window).on("load", function() {
	if(device == 'mobile'){
		$(".menuList .service, .menuList .classic").on("click", function(){
			$(this).toggleClass("is-open")
			$(this).find(".menuSub").slideToggle(300)
		})
	}
	
	titleEnter()
	footerEnter()
})


// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);